import { splitProps } from 'solid-js'
import { css, size } from '~/libs'

type Props = ComponentProps<'main'>

export function Main(props: Props) {
  const [{ children, class: root = '' }, attr] = splitProps(props, ['children', 'class'])
  const styled = {
    root: css({
      ...size({ height: '100%' })
    })
  }
  const classes = [styled.root, root].filter(Boolean).join(' ')
  return <main class={classes} {...attr}>{children}</main>
}
